import React, { useEffect } from 'react'
import { navigate } from 'gatsby-link'

import { CONTACT_LINKS } from 'constants/links'

export default function Redirect() {
  useEffect(() => {
    navigate(CONTACT_LINKS[0].link)
  }, [])

  return <div style={{ height: '100vh' }} />
}
